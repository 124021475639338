@charset "utf-8";
@import "webfont.css";

// Define defaults for each variable.

$base-font-family: "Iosevka SS01 Web" !default;
$base-font-size:   16px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     30px !default;

$text-color:       #eee !default;
$background-color: #111 !default;
$brand-color:      #6ae1c8 !default;
$brand-color-light:#7af1d8 !default;

$jg-zenburn-green: #94BC2D;
$jg-zenburn-yellow: #FFEA77;
$jg-zenburn-magenta: #FA4785;
$jg-zenburn-blue: #56bbff;
$jg-zenburn-orange: #FF8b49;
$jg-grey-text: #bbb;

$code-background: #000;

$grey-color:       #d6d6d6 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

$table-text-align: left !default;

// Width of the content area
$content-width:    800px !default;

$on-palm:          600px !default;
$on-laptop:        800px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Import partials.
@import
  "minima/base",
  "minima/layout",
  "minima/syntax-highlighting"
;
